.width {
    max-width: 720px;
    width: 100%;
    height: 289px;
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 24px;
}

@media screen and (min-width:1197px) {
    .content-safety {
        width: 100%;
        margin: 0px 157px;
    }

}

.SafetyBox {
    width: 100%;
    padding: 20px;
}

.width h1 {
    font-size: 20px;
    margin-bottom: 24px;
}

.email-box {
    border: 1px solid #f3f3f3;
    justify-content: space-between;
    border-radius: 10px;
    height: 52px;
    align-items: center;
    display: flex;
    padding: 0px 10px;
}

.email-box div p {
    color: #94a3b8;
    font-size: 14px;
}

.email-box:last-child {
    display: flex;
}

.email-box div h5 {
    font-size: 14px;
    font-weight: 400;
}

.edit a {
    color: #0f4eb2;
    font-size: 12px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 332px;
    height: 52px;
    margin: 16px 0px;
    border: 1px solid #df1b1b;
    background-color: #fbe3e3;
    border-radius: 10px;
}

.error img {
    margin-right: 5px;
}

.error p {
    color: #df1b1b;
    font-size: 14px;
}

.error a {
    color: #0f4eb2;
    text-decoration: none;
    font-weight: 600;
}

.api-token {
    height: 208px;

}

.space {
    margin-bottom: 10px;
}

.Two-factor {
    height: 321.6px !important;

}

.Two-factor h1 {
    margin-bottom: 24px;
}

.blue-btn {
    color: #1968e5;
    padding: 8px 12px;
    border-radius: 10px;
    border: 1px solid #1968e5;
    background-color: white;
    cursor: pointer;
}

.blue-btn:hover {
    background-color: #00c0ff;
}

.blue-btn:first-child {
    margin-right: 16px;
}

.box {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

.levels {
    margin-top: 30px;
}

.red-btn {
    height: 36px;
    color: #b3261e;
    border-color: #b3261e;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    transition: all 0.2s ease;
    margin-left: auto;
}

.red-btn {
    color: rgba(223, 27, 27, .8);
    border-color: rgba(223, 27, 27, .8);

}

.button {
    width: 720px;
    display: flex;

}

.last-activity {
    padding: 24px 0 0 24px;
    height: 341px;
}

@media screen and (min-width: 777px) and (max-width: 991px) {

    .width,
    .button {
        width: 638px;
    }
}

@media screen and (max-width: 776px) {

    .width,
    .button {
        width: 100%;
    }
}

.ash {
    color: #686c77;
}