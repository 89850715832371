.Residence {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.residencebox1 {
    width: 64%;
    height: 247px;
    padding: 24px;
    display: block;
    border: 1px solid #f3f3f3;
    border-radius: 20px;
}

.residencebox1 h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 18px;
}

.residencebox1 h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
}

.choose-box {
    width: 100%;
    height: 56px;
}

.choose {
    width: 100%;
    height: 56px;
    border-radius: 10px;
    background-color: transparent;
}

.residencebox1 .btn {
    margin-top: 24px;
}

.height {
    height: 100vh;
}

.residencebox2 {
    width: 34%;
    height: 209px;
    margin-left: auto;
    border: 1px solid #f3f3f3;
    border-radius: 20px;
    padding: 24px;
}

.residencebox2 h1 {
    font-size: 16px;
    font-weight: 500;
}

.line2 {
    display: flex;
    align-items: center;

}

.line2 p {
    color: #686c77;
    font-size: 12px;
}

.gray {
    color: #333742;
    opacity: 0.4;
}

.grey {
    opacity: 0.2;
}

.cover-img {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    background-color: rgba(17, 17, 17, .075);
    color: rgba(17, 17, 17, .6);
    display: grid;
    place-items: center;
    border-radius: 50%;
}

.margin19 {
    margin-top: 19px;
}

.small-exes {
    margin-top: 10px;
    display: block;
    margin-left: 23px;
}

.Ex {
    height: 10px;
    width: 10px;
    opacity: 0.4;
    color: #df1b1b;
}

.moc {
    margin-bottom: 2px;
}

.btn {
    padding: 8px 12px;
    color: #fefefe;
    background-color: black;
    height: 36px;
    font-size: 14px;
    border-radius: 10px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
}

.btn:hover {
    opacity: 0.8;
}

/* @media screen and (min-width:1197px) {
    .content-veri{
       width:638px !important;
       margin: 0px 157px;
    }
    
} */

@media screen and (min-width:984px) and (max-width:1197px) {
    .content-veri {
        width: 852px !important;
    }

}

@media screen and (min-width:778px) and (max-width:983px) {
    .content-veri {
        width: 638px !important;
    }

    .Residence {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 20px;
    }

    .residencebox1 {
        width: 100%;
    }

    .residencebox2 {
        width: 100%;
        margin-bottom: 30px;
    }

}