small {
    font-size: 12px;
    color: #686c77;
}

.Profile-settings {
    height: 155px;
    width: 70%;
    border: 1px solid #e1e8f1;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 24px;
}

.Profile-settings:first-child {
    margin-top: 40px !important;
}

.margin {
    margin-bottom: 40px;
}

.profile-circle {
    height: 62px;
    width: 62px;
    border-radius: 50%;
    border: 2px solid #df1b1b;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-details {
    display: flex;
}

.Profile-settings h1 {
    margin-bottom: 18px;
    font-size: 20px;
}

.profile-circle p {
    color: #1968e5;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 700;
}

.user-details {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.Date-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Date-time p {
    font-size: 14px;
    width: fit-content;
    height: fit-content;

}

.Date-time:last-child {
    margin-top: 20px;
}

select {
    background-color: #d9d9d9;
    border-radius: 8px;
    height: 32px;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    width: 210px;
}

.Profile-settings:last-child {
    padding: 12px 24px;
}

@media screen and (min-width: 777px) and (max-width: 989px) {
    .Profile-settings {
        width: 638px;
    }

}

@media screen and (max-width: 776px) {
    .Profile-settings {
        width: 300px;
    }
}