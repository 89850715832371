html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-family: Roboto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark_button {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  border-color: rgba(17, 17, 17, 0);
  color: rgba(254, 254, 254, 1);
  background: rgba(17, 17, 17, 0.075);
  padding: 8px 12px;
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 125px;
  cursor: pointer;
}

/* .dark_button.disabled {
  color: #1968e5;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
} */



.error {
  color: #ff3344;
  font-size: 14px;
  margin-top: 8px;
}

.first-name-placement,
.last-name-placement,
.email-placement,
.password-placement,
.confirm-placement,
.phone-placement {
  font-size: 14px;
  display: none;
  margin-top: 8px;
  opacity: 0;
}

.blue_bg_button {
  background-color: #1968e5;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
}

.blue_text {
  color: #1968e5;
}

.blue_bg_button.disabled {
  opacity: 0.3;
  cursor: not-allowed;
  user-select: none;
}

.blue_button {
  color: #1968e5;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.blue_button_outline {
  color: #1968e5;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  background: none;
  outline: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid rgba(217, 217, 217, 1);
  cursor: pointer;
}

.dark_button {
  color: rgba(254, 254, 254, 1);
  background: rgba(17, 17, 17, 1);
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid transparent;
  font-family: Roboto;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  position: relative;
  height: 36px;
  cursor: pointer;
}

.dark_button.disabled {
  background: rgba(17, 17, 17, 0.075);
  user-select: none;
  cursor: not-allowed;
}


.red_button {
  color: rgba(254, 254, 254, 1);
  background: #ff3344;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid transparent;
  font-family: Roboto;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  position: relative;
  height: 36px;
  cursor: pointer;
}

.red_button.disabled {
  background: rgba(17, 17, 17, 0.075);
  user-select: none;
  cursor: not-allowed;
}

.mt-12 {
  margin-top: 12px;
}


.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}